import moment from 'moment'
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import campsiteConfig from '@/config/campsite.config'
import _ from 'lodash'

const TARGET_KEYS = {
  Publishers: 'supplier',
  CreativeFormats: 'creativetype',
  CreativeSizes: 'creativeformat',
  MeasurementPartners: 'measurementpartner',
  Currency: 'currency'
}

const NON_DEMOGRAPHIC_TARGETS = ['weekpart', 'daypart', 'creativeformat', 'screensizerange', 'supplier', 'creativetype', 'measurementpartner']

export default {
  numberWithCommasFormatting,
  ImpressionsShortFormatting,
  buildTextBreadcrumbs,
  buildVenueFullAddress,
  venuesQueryStringBuilder,
  isForecastImpressionsNa,
  getListOfTargetValues,
  getAddedAndRemovedItemsOfList,
  getTargetValueLabels,

  formatDates,
  formatSchedule,

  screenSizeFormatting,
  formatScreens,
  formatScreenIntoKeyValueList,
  getSelectedScreenTarget,
  convertToApiFormatScreenTarget,
  getListOfSimpleTarget,
  getOrientation,
  getListOfCreativeSizes,
  getGroupedAndUngroupedListOfSimpleTargets,

  removingEndDigitMobileTarget,
  mobileTargetsToTargetTargetValuesFormatting,
  getExtremumFromTreeList,
  customTicsFormattingToSlider,
  formatMobileTargets,
  getActiveMobileTargetGroups,
  getDeprecatedMobileTargetGroups,
  isTargetGroupExpanded,

  formatGeoTargetsFull,

  extractChildren,
  flattenTargetGroups,
  fixTargetFromTargets,
  fixTargetFromTargetGroups,
  isGroupAtarget,
  filterActiveTargetGroup,
  selectedTargetsPerGroup,

  formatEnvironments,
  showEnvSection,
  getKeyLabelOfLowestChildrenPerEnv,
  extractLowestChildrenPerEnv,
  findChangedEnvs,
  getLabelFromKey,
  buildEnvApiFormat,
  buildEnvSegmentLeaves,
  generateEnvUniqueKeys,

  getBidRangeLowerEnd,
  getBidRangeUpperEnd,
  getEstimatedMaxBudget,
  getSelectedCurrencyTarget,

  NON_DEMOGRAPHIC_TARGETS,

  getMomentEventToEmit,
  needToFixMoment,
  canByPassMomentSteps,
  GetMinMaxForecastBidRange
}

// ========================
// General Helper Functions
// ========================
function numberWithCommasFormatting (nbr) {
  return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function ImpressionsShortFormatting (nbr) {
  return (nbr / 1000000).toFixed(1).toString() + ' M'
}

function buildTextBreadcrumbs (list, separator = '🢒') {
  if (!list || !list.length) return
  return list.join(` ${separator} `)
}

function buildVenueFullAddress (venue) {
  const list = [venue.address, venue.city, venue.state, venue.postalCode]
  return list.filter(x => Boolean(x)).join(', ')
}

function venuesQueryStringBuilder (paginationOptions) {
  const params = []

  params.push('take=' + paginationOptions.take)
  params.push('skip=' + paginationOptions.skip)

  if (paginationOptions.query) {
    const searchArgs = []
    const searchableFields = ['environment', 'name', 'city', 'address']
    searchableFields.forEach(col => {
      searchArgs.push(col + ':' + paginationOptions.query)
    })
    params.push('filter=' + searchArgs.join(' OR '))
  }
  return params
}

function isForecastImpressionsNa (forecast, hasDeals, hasMomment) {
  if (!forecast || !forecast.impressions) return true
  return forecast.impressions.total === 0 || hasDeals || hasMomment
}

function getListOfTargetValues (targeting) {
  if (!targeting) targeting = []
  const targetValuesToKeep = []
  for (const tar of targeting) { targetValuesToKeep.push(...tar.targetValues) }
  return targetValuesToKeep
}

function getAddedAndRemovedItemsOfList (listBefore, listAfter) {
  const checked = listAfter.filter(x => !listBefore.includes(x))
  const unchecked = listBefore.filter(x => !listAfter.includes(x))
  return { checked, unchecked }
}

function getTargetValueLabels (values, options) {
  return values.map(x => {
    const item = options.find(p => p.value === x)
    return item ? item.name : x
  })
}

// =============
// Audience Page
// =============
function formatDates (audienceDetails) {
  if (audienceDetails.startDate && audienceDetails.endDate) {
    const startDate = moment(audienceDetails.startDate, 'YYYY-MM-DD h A').format('MMM D, YYYY')
    const endDate = moment(audienceDetails.endDate, 'YYYY-MM-DD h A').format('MMM D, YYYY')
    return startDate + ' to ' + endDate
  } else {
    return 'N/A'
  }
}

function formatSchedule (audienceDetails) {
  let hours = ''
  let days = ''

  let scheduleDays = audienceDetails.audienceSegments.find(x => x.target === 'weekpart')
  const scheduleHours = audienceDetails.audienceSegments.find(x => x.target === 'daypart')

  if (scheduleDays || scheduleHours) {
    if (scheduleDays) {
      scheduleDays = [...scheduleDays.targetValues].map(x => x.replace('d-', '')).sort().join('')
      if (scheduleDays === '12345') {
        days = 'on Weekdays'
      } else if (scheduleDays === '06') {
        days = 'on Weekends'
      } else if (scheduleDays === '0123456') {
        days = 'Everyday'
      } else {
        days = 'on ' + dayFormattingHelper(scheduleDays)
      }
    }
    if (scheduleHours) {
      hours = hoursFormattingHelper(scheduleHours.targetValues)
    }
    return hours + days
  } else {
    return 'Run ads all the time'
  }
}

function dayFormattingHelper (scheduleDaysAsOneString) {
  const days = ['Sundays', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays']
  return scheduleDaysAsOneString.split('').map(x => days[parseInt(x)]).join(', ')
}

function hoursFormattingHelper (hoursList) {
  hoursList = [...hoursList].map(x => parseInt(x)).sort((a, b) => a - b)
  let orderedHours = []
  for (let i = 0; i < hoursList.length; i++) {
    if (hoursList[i] + 1 !== hoursList[i + 1]) {
      orderedHours = [...hoursList.splice(i + 1), ...hoursList.slice(0, i + 1)]
      break
    }
  }

  function hourFormatting (hour) {
    if (hour === 0 || hour === 24) { return '12:00 AM' }
    if (hour === 12) { return '12:00 PM' }
    if (hour > 0 && hour < 12) { return hour + ':00 AM' }
    if (hour > 12) { return hour - 12 + ':00 PM' }
  }

  if (orderedHours.length) {
    // we add 1 to "end" hour because we phrase it like "8am to 11am", when API returns [8,9,10]
    return hourFormatting(orderedHours[0]) + ' to ' + hourFormatting(orderedHours[orderedHours.length - 1] + 1) + ' '
  } else {
    return hourFormatting(hoursList[0]) + ' to ' + hourFormatting(hoursList[hoursList.length - 1]) + ' '
  }
}

// ========================
// SCREEN TARGETING RELATED
// ========================
function screenSizeFormatting (screenDictFromStore) {
  const screenSizeTics = {
    labels: [],
    values: []
  }
  if (screenDictFromStore && screenDictFromStore.targetValues) {
    screenDictFromStore.targetValues.forEach(option => {
      screenSizeTics.labels.push(option.name)
      screenSizeTics.values.push(option.value)
    })
  }
  return screenSizeTics
}

function formatScreens (audienceSegments) {
  let label = ''
  const orientation = audienceSegments.find(x => x.target === 'creativeformat')
  let sizes = audienceSegments.find(x => x.target === 'screensizerange')

  if (!orientation && !sizes) {
    return 'Landscape & Portrait - All sizes'
  }

  if (orientation) {
    label = orientation.targetValues.join(' & ')
  //   label += orientation.targetValues[0]
  // } else {
  //   label += 'Landscape & Portrait'
  }
  if (sizes) {
    sizes = sizes.targetValues.map(x => x.split('-')).flat().map(x => x + '"')
    label += ' - ' + sizes[0] + ' to ' + sizes[sizes.length - 1]
  }
  return label
}

function formatScreenIntoKeyValueList (targeting, initialTargeting = [], targets) {
  if (!targeting || !targeting.length) return null
  const sections = [
    { key: 'Publishers', label: 'Publishers' },
    { key: 'CreativeFormats', label: 'Creative Formats' },
    { key: 'CreativeSizes', label: 'Creative Sizes' },
    { key: 'DiagonalLength', label: 'Diagonal Length' },
    { key: 'MeasurementPartners', label: 'Measurement Partners' }
  ]
  const list = []
  for (const section of sections) {
    const selected = getSelectedScreenTarget(section.key, targeting)
    if (selected.length) {
      const fullList = getListOfSimpleTarget(section.key, targets, initialTargeting)
      list.push({
        key: section.label,
        value: getTargetValueLabels(selected, fullList).join(', ')
      })
    }
  }
  return list.length ? list : null
}

function getSelectedScreenTarget (target, targetingList) {
  const formatTarget = targetingList.find(x => x.target === TARGET_KEYS[target])
  return formatTarget ? formatTarget.targetValues : []
}

function getSelectedCurrencyTarget (target, targetingList) {
  const currencyTarget = targetingList?.find(x => x.target === TARGET_KEYS[target])
  return currencyTarget ? currencyTarget.targetValues[0] : null
}

function convertToApiFormatScreenTarget (target, values) {
  if (!TARGET_KEYS[target]) return
  return { target: TARGET_KEYS[target], targetValues: values }
}

function getListOfSimpleTarget (key, targets, initialTargeting = []) {
  if (!targets || !TARGET_KEYS[key]) return []
  let target = targets.find(g => g.value === TARGET_KEYS[key])
  if (!target) return []
  target = fixTargetFromTargets(target, getListOfTargetValues(initialTargeting))
  if (!target) return []
  return target.targetValues.map(v => {
    return { name: v.name, value: v.value }
  }).sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1)
}

function getOrientation (format) {
  if (!format) return
  const w = parseInt(format.split('x')[0])
  const h = parseInt(format.split('x')[1])
  if (!w || !h) return

  if (w === h) return 'square'
  else if (w > h) return 'landscape'
  else return 'portrait'
}

function getListOfCreativeSizes (targets, initialTargeting = []) {
  if (!targets || !targets.find(target => target.name === TARGET_KEYS.CreativeSizes)) return
  const target = fixTargetFromTargets(targets.find(target => target.name === TARGET_KEYS.CreativeSizes), getListOfTargetValues(initialTargeting))
  if (!target) return

  const values = target.targetValues.map(tv => {
    let label = tv.value.replace('x', ' x ')
    if (tv.name.includes('deprecated')) label += ' (deprecated)'
    return { ...tv, name: label }
  }).sort((a, b) => parseInt(b.value.split('x')[0]) - parseInt(a.value.split('x')[0]))

  const obj = {}
  for (const orientation of ['Landscape', 'Portrait', 'Square']) {
    const vals = values.filter(v => getOrientation(v.value) === orientation.toLowerCase())
    if (vals.length) {
      obj[orientation.toLowerCase()] = [{
        value: orientation,
        name: orientation,
        children: vals
      }]
    }
  }
  return { grouped: obj, ungrouped: values }
}

function getGroupedAndUngroupedListOfSimpleTargets (key, targets, initialTargeting = []) {
  const ungrouped = getListOfSimpleTarget(key, targets, initialTargeting)
  return {
    grouped: { all: [{ value: `all-${key}`, name: 'All', children: ungrouped }] },
    ungrouped
  }
}

// ========================
// MOBILE TARGETING RELATED
// ========================
function removingEndDigitMobileTarget (list) {
  // ex. avid-reader-9 => avid-reader
  const formatted = []
  const re = /-\d+/g
  if (list && list.length > 0) {
    list.forEach(element => {
      if (!formatted.includes(element.replace(re, ''))) {
        formatted.push(element.replace(re, ''))
      }
    })
  }
  return formatted
}

function mobileTargetsToTargetTargetValuesFormatting (list) {
  const re = /-\d+/g
  const dict = {}
  const res = []
  if (list && list.length > 0) {
    list.forEach(element => {
      const label = element.replace(re, '')
      if (dict[label]) {
        dict[label].push(element)
      } else {
        dict[label] = [element]
      }
    })

    Object.keys(dict).forEach(key => {
      res.push({ target: key, targetValues: dict[key] })
    })

    return res
  }
}

function getExtremumFromTreeList (list) {
  // Finds the MIN and MAX for a mobile target section ex. [avid-reader-4, avid-reader-5, avid-reader-6] => [4,6]
  const allValues = []
  if (list && list.length > 0) {
    list.forEach(element => {
      const splitByDash = element.split('-')
      allValues.push(parseInt(splitByDash[splitByDash.length - 1]))
    })
    return [Math.min(...allValues), Math.max(...allValues)]
  }
}

function customTicsFormattingToSlider (selected, valuesList, type) {
  let selectedList = []
  const indxList = []
  if (type === 'Screen') {
    selectedList = selected
  } else {
    selectedList = selected[0].split('-')
  }
  selectedList.forEach(element => {
    indxList.push(valuesList.indexOf(element))
  })
  return [Math.min(...indxList), Math.max(...indxList)]
}

function formatMobileTargets (audienceSegments, mobileTargetsDict, currency = '$') {
  if (typeof audienceSegments !== 'object' || !mobileTargetsDict.length) return null
  else {
    const list = []
    const mobile = audienceSegments.filter(seg => !NON_DEMOGRAPHIC_TARGETS.includes(seg.target))
    if (mobile.length) {
      mobile.map(segment => {
        if (segment.target === 'age-range') {
          const interval = segment.targetValues.map(x => x.split(/_|-/g)).flat()
          list.push('Age Range ' + interval[0] + '-' + interval[interval.length - 1])
        } else if (segment.target === 'income-range') {
          let interval = segment.targetValues.map(x => x.split('-')).flat()
          interval = interval.map(x => {
            if (['under_25k', 'over_100k'].includes(x)) {
              return x === 'under_25k' ? `<${currency}25k` : `>${currency}100k`
            } else { return x.split('_').map(y => currency + y) }
          }).flat()
          if (interval[0] === interval[interval.length - 1]) {
            list.push('Income Range ' + interval[0])
          } else {
            list.push('Income Range ' + interval[0] + '-' + interval[interval.length - 1])
          }
        } else {
          let segmentName
          for (const group of mobileTargetsDict) {
            const target = group.groups ? group.groups.find(g => g.key === segment.target) : null
            if (target) {
              segmentName = `${group.name} ${target.name}`
              break
            }
          }
          if (!segmentName) segmentName = segment.target
          list.push(segmentName)
        }
      })
    }
    return list.length ? list.join(', ') : null
  }
}

function getDeprecatedMobileTargetGroups (targetGroups) {
  return targetGroups.filter(g => ['Age', 'Income'].includes(g.key)).map(g => {
    return {
      ...g,
      key: g.key + '-range',
      targets: g.targets.filter(t => t.status === 'Deprecated')
    }
  })
}

function getActiveMobileTargetGroups (targetGroups, initialTargeting = []) {
  const visibleDemographicGroups = ['Age', 'Income', 'InterestActivity', 'LifeStage', 'Intent', 'Gender']
  const targetValuesToKeep = getListOfTargetValues(initialTargeting)
  return targetGroups
    .filter(g => visibleDemographicGroups.includes(g.key))
    .map(g => filterActiveTargetGroup(g, targetValuesToKeep))
}

function isTargetGroupExpanded (details) {
  const { targetKey, targeting, targetGroups, currentState } = details
  if (['Age-range', 'Income-range'].includes(targetKey) && targeting.find(t => t.target === targetKey.toLowerCase())) {
    return 1
  }
  const controllerCopy = []
  if (targetGroups.length && targeting.length) {
    targetGroups.map(targetGroup => {
      const children = extractChildren(targetGroup)
      const groupKey = targetGroup.key.replace(/[&\s]/g, '')
      var groupValues = []
      targeting.filter(t => !['age-range', 'income-range'].includes(t.target)).map(target => {
        if (children.includes(target.target)) {
          groupValues.push(target.targetValues)
        }
      })
      if (groupValues.length) {
        // open widget
        controllerCopy.push({
          key: groupKey,
          value: 1
        })
      }
    })
  }
  let existing = controllerCopy.find(target => target.key === targetKey)
  if (!existing && currentState.length) {
    existing = currentState.find(target => target.key === targetKey)
  }

  return existing ? existing.value : 0
}

// =====================
// GEO TARGETING RELATED
// =====================
function formatGeoTargetsFull (geography, displayed = 2, unit = 'metric') {
  if (!geography) return null
  else {
    const listToDisplay = []
    const hidden = []
    const list = geoService.geoFromApiFormatting(geography, unit)

    for (const x of list) {
      let out = ''
      out += !x.isIncluded ? 'Exclude ' : ''
      if (x.type === 'point_of_interest') {
        out += x.data.radius + ' around ' + x.data.type.name
      } else {
        out += x.label
        out += x.radius && !['City Only', 'Venue Only'].includes(x.radius) ? ' + ' + x.radius : ''
      }
      if (listToDisplay.length < displayed) listToDisplay.push(out)
      else hidden.push(out)
    }
    return !listToDisplay.length && !hidden.length ? null : { listToDisplay, hidden }
  }
}

// =================================
// TARGETS AND TARGETGROUPS SPECIFIC
// =================================
function extractChildren (rootTarget) {
  var out = []

  if (rootTarget.groups && rootTarget.groups.length) {
    rootTarget.groups.map(group => {
      var childs = extractChildren(group)
      if (childs.length) {
        out.push(...childs)
      }
    })
  }

  if (rootTarget.targets) {
    rootTarget.targets.map(target => {
      out.push(target.key)
    })
  }

  return out
}

function flattenTargetGroups (targetgroups) {
  return targetgroups
    .map(tg => {
      return extractKeyNames(tg)
    })
    .reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue)
    },
    []
    )
}

function extractKeyNames (tg) {
  var out = []

  if (tg.groups && tg.groups.length) {
    tg.groups.map(group => {
      var childs = extractKeyNames(group)
      if (childs.length) {
        out.push(...childs)
      }
    })
  }

  if (tg.targets) {
    tg.targets.map(target => {
      out.push({
        key: target.key,
        name: target.name
      })
    })
  }

  return out
}

function fixTargetFromTargetGroups (target, targetValuesToKeep = []) {
  if ((target.status === 'Deprecated' || target.key.includes('unknown')) && target.values.map(t => t.key).every(t => !targetValuesToKeep.includes(t))) return null
  const newVals = []
  target.values.forEach(val => {
    if (val.status !== 'Deprecated' && !val.key.includes('unknown')) newVals.push(val)
    else if (val.status === 'Deprecated' && targetValuesToKeep.includes(val.key)) {
      newVals.push({ ...val, name: `${val.name} (deprecated)` })
    } else if (val.key.includes('unknown') && targetValuesToKeep.includes(val.key)) {
      newVals.push(val)
    }
  })
  return { ...target, values: newVals }
}

function fixTargetFromTargets (target, targetValuesToKeep = []) {
  if (target.status === 'Deprecated' && target.targetValues.map(t => t.key).every(t => !targetValuesToKeep.includes(t))) return null
  const newVals = []
  target.targetValues.forEach(val => {
    if (val.status !== 'Deprecated') newVals.push(val)
    else if (val.status === 'Deprecated' && targetValuesToKeep.includes(val.value)) {
      newVals.push({ ...val, name: `${val.name} (deprecated)` })
    }
  })
  return { ...target, targetValues: newVals }
}

function isGroupAtarget (group) {
  return !(group.groups && group.targets)
}

function filterActiveTargetGroup (targetGroup, targetValuesToKeep) {
  const group = JSON.parse(JSON.stringify(targetGroup))

  if (group.key === 'Income') {
    // 100k+ is first so we need to manually re-order
    group.targets = [...targetGroup.targets.slice(1), targetGroup.targets[0]]
    group.groups = [...targetGroup.groups.slice(1), targetGroup.groups[0]]
  }

  if (group.targets) group.targets = group.targets.map(t => fixTargetFromTargetGroups(t, targetValuesToKeep)).filter(t => Boolean(t))
  if (group.groups) {
    group.groups.forEach((g, i) => {
      group.groups[i] = isGroupAtarget(g)
        ? fixTargetFromTargetGroups(g, targetValuesToKeep)
        : filterActiveTargetGroup(g, targetValuesToKeep)
    })
    group.groups = group.groups.filter(g => Boolean(g))
  }
  return group
}

function selectedTargetsPerGroup (targetKey, groups, targeting) {
  var final = []
  groups.map(targetGroup => {
    const children = extractChildren(targetGroup)
    const groupKey = targetGroup.key.replace(/[&\s]/g, '')
    var groupValues = []

    targeting.map(target => {
      if (children.includes(target.target)) {
        groupValues.push(target.targetValues)
      }
    })
    if (groupValues.length) {
      final.push({
        key: groupKey,
        values: helpers.arrayFlat(groupValues)
      })
    }
  })
  const selectedMobileTarget = final.find(target => target.key === targetKey)
  return selectedMobileTarget ? selectedMobileTarget.values : []
}

// ============
// Environments
// ============

// TODO: rename to "environmentChildsString"
function formatEnvironments (segments, taxonomy) {
  if (!segments || !segments.groups || !taxonomy) return null

  // prefix ("l.") used to identify "new" taxonomy terms
  const prefixRegex = /^(l\.)/

  const childs = []

  const groups = segments.groups
  groups.map(group => {
    const filters = group.filters
    const filtersLength = filters.length

    // Parent
    if (filtersLength === 1) {
      const filter = filters[0]

      const isNewTaxonomy = prefixRegex.test(filter.value)
      if (isNewTaxonomy) {
        // NEW taxonomy :: find all childs
        const parent = taxonomy.find(t => t.key === filter.value)
        if (parent && parent.children) {
          parent.children.map(child => {
            childs.push(child)
          })
        }
      } else {
        // OLD taxonomy :: Parent are considered as child
        childs.push(filter)
      }
    } else {
      // childs -or- grandchilds :: find child
      let child

      filters.map(filter => {
        // remove prefix ("l.") used to identify "new" taxonomy terms
        const filterValue = filter.value.replace(prefixRegex, '')
        if (!child && filterValue.includes('.')) {
          child = filter
        }
      })

      if (child) {
        childs.push(child)
      }
    }
  })

  const uniqueChilds = new Set(childs.map(child => child.label))
  return [...uniqueChilds].length ? [...uniqueChilds].sort().join(', ') : null
}

function getKeyLabelOfLowestChildrenPerEnv (env, parentLabel) {
  if (!env.children || !env.children.length) {
    return [{
      key: env.uniqueKey,
      label: `${!parentLabel ? '' : parentLabel + ' - '}${env.label}`
    }]
  } else {
    const list = []
    for (const child of env.children) {
      list.push(...getKeyLabelOfLowestChildrenPerEnv(child, parentLabel ? `${parentLabel} - ${env.label}` : env.label))
    }
    return list
  }
}

function extractLowestChildrenPerEnv (env) {
  if (!env.children || !env.children.length) {
    return [env.uniqueKey]
  } else {
    const list = []
    for (const child of env.children) {
      list.push(...extractLowestChildrenPerEnv(child))
    }
    return list
  }
}

function findChangedEnvs (beforeList, afterList) {
  const checked = afterList.filter(env => !beforeList.includes(env))
  const unchecked = beforeList.filter(env => !afterList.includes(env))
  return { checked, unchecked }
}

function showEnvSection (envsTaxonomy) {
  if (!envsTaxonomy || !envsTaxonomy.length) return false
  else if (envsTaxonomy.length > 1) return true
  else {
    return uniqueChild(envsTaxonomy[0])
  }
}

function uniqueChild (envr) {
  if (envr.children.length > 1) return true
  if (envr.children.length === 1) return uniqueChild(envr.children[0])
}

function getLabelFromKey (key, mapping = []) {
  const item = mapping.find(x => x.key === key)
  return item ? item.label : key
}

function buildEnvApiFormat (selectedElements, taxonomy) {
  const buildGroup = (filters) => {
    return {
      operation: 'and',
      filters: filters.map(f => {
        return { label: f.label, value: f.key, selectionRule: 'include' }
      })
    }
  }

  // (!) v-treeview only returns leafs (!)
  // one "group" per parent
  const groups = []

  // extract "keys", easier to search
  const selectedKeys = selectedElements.map(el => el.uniqueKey)

  // consider every "leaf" as a "child" for now
  let selectedChildKeys = [...selectedKeys]

  // array to contain "child" selected because all "grandchild" are selected
  // we need to do this since v-treeview only returns "leaf"
  const selectedChildsByGrandchilds = []

  // parse "grandchild"
  const grandchilds = selectedElements.filter(el => el.parentKey && el.parentKey.indexOf('.') > -1)
  grandchilds.map(gc => {
    const [parentKey] = gc.parentKey.split('.')

    const parent = taxonomy.find(t => t.uniqueKey === parentKey)
    const child = parent.children.find(c => c.uniqueKey === gc.parentKey)
    const allSiblingsSelected = child.children.every(c => selectedKeys.includes(c.uniqueKey))

    if (allSiblingsSelected) {
      // all "siblings" of this "granchild" are also selected
      if (!selectedChildsByGrandchilds.find(c => c.uniqueKey === child.uniqueKey)) {
        // so consider "child" as selected
        selectedChildsByGrandchilds.push(child)

        // add "child"'s key to list since v-treeview doesn't include it
        selectedChildKeys.push(child.uniqueKey)
      }
    } else {
      groups.push(buildGroup([parent, child, gc]))
    }

    // filter out "grandchilds" from "childs"
    selectedChildKeys = selectedChildKeys.filter(rk => rk !== gc.uniqueKey)
  })

  const parents = []
  const selectedChilds = selectedElements.filter(el => selectedChildKeys.includes(el.uniqueKey))
  const childs = selectedChilds.concat(selectedChildsByGrandchilds)
  childs.map(c => {
    // singleton
    if (!c.parentKey) {
      groups.push(buildGroup([c]))
    } else {
      const parent = taxonomy.find(t => t.uniqueKey === c.parentKey)
      const allSiblingsSelected = parent.children.every(c => selectedChildKeys.includes(c.uniqueKey))
      if (allSiblingsSelected) {
        if (!parents.find(p => p.uniqueKey === c.parentKey)) {
          parents.push(parent)
        }
      } else {
        groups.push(buildGroup([parent, c]))
      }
    }
  })

  parents.map(p => {
    groups.push(buildGroup([p]))
  })

  return groups.length
    ? {
      operation: 'or',
      groups: groups
    }
    : {}
}

function buildEnvSegmentLeaves (segments, taxonomy) {
  return segments.groups.map(group => {
    if (group.filters && group.filters.length) {
      const uniqueKeys = group.filters
        .map(filter => {
          // yet another inconsistency, audience.segments are stored using leaf.value VS leaf.key
          filter.key = filter.value
          return filter
        })
        .map(generateEnvUniqueKeys)
        .map(filter => filter.uniqueKey)

      const leafUniqueKey = findLeafUniqueKey(uniqueKeys, taxonomy)

      return leafUniqueKey ? { uniqueKey: leafUniqueKey } : null
    }
  }).filter(leaf => leaf !== null)
}

function findLeafUniqueKey (segmentUniqueKeys, environments, parentUniqueKey = '') {
  const segmentUniqueKeysWithParentKey = segmentUniqueKeys.map(k => parentUniqueKey ? parentUniqueKey + '.' + k : k)

  // We handle all the possible environments matches in case there are duplicated names between parents and children
  const environmentsMatchingASegmentKey = environments.filter(e => segmentUniqueKeysWithParentKey.includes(e.uniqueKey))

  let leafUniqueKey
  if (segmentUniqueKeys.length !== 1) {
    const possibleUniqueKeys = environmentsMatchingASegmentKey.map(environment => {
      if (!environment.children?.length) return environment.uniqueKey

      const remainingUniqueKeys = [...segmentUniqueKeys]
      const indexToRemove = segmentUniqueKeysWithParentKey.findIndex(k => k === environment.uniqueKey)
      if (indexToRemove !== -1) remainingUniqueKeys.splice(indexToRemove, 1)

      return findLeafUniqueKey(remainingUniqueKeys, environment.children, environment.uniqueKey)
    })

    leafUniqueKey = possibleUniqueKeys.find(key => key !== null)
  } else {
    leafUniqueKey = environmentsMatchingASegmentKey[0]?.uniqueKey
  }

  return leafUniqueKey || null
}

// Generate unique keys recursively by prepending parent(s) key(s)
// Based on naming convention in Reach Google Doc, i.e.
//   "parent.child.grandChild"
//
// API not consistent with taxonomy terms keys
//   "childs" already have the "parent" prepend, ex: "parentKey.childKey"
//   "grandchilds" only have themselves, i.e. "grandchildKey"
//
// TODO: use "leaf" & "node" (VS "unique" & "parent") to describe tree
//       and use "parent", "child" & "grandChild" to describe taxonomy
function generateEnvUniqueKeys (item) {
  // remove prefix ("l.") used to identify "new" taxonomy terms
  var itemKey = item.key.replace(/^(l\.)/, '')

  if (itemKey.indexOf('.') > -1) {
    const itemKeyPieces = itemKey.split('.')
    itemKey = itemKeyPieces[itemKeyPieces.length - 1]
  }

  if (item.children && item.children.length) {
    item.children.map(c => {
      c.parentKey = item.parentKey
        ? item.parentKey + '.' + itemKey
        : itemKey
      return generateEnvUniqueKeys(c)
    })
  }

  item.uniqueKey = item.parentKey
    ? item.parentKey + '.' + itemKey
    : itemKey

  return item
}

// Phase 2 Report Filters (spport for any/none of)
// function getAllLeafsPerEnv (env, parentLabel) {
//   if (!env.children || !env.children.length) {
//     return [{
//       key: env.key,
//       label: `${!parentLabel ? '' : parentLabel + ' - '}${env.label}`
//     }]
//   } else {
//     const list = []
//     list.push({
//       key: env.key,
//       label: `${!parentLabel ? '' : parentLabel + ' - '}${env.label}`
//     })
//     for (const child of env.children) {
//       list.push(...getAllLeafsPerEnv(child, parentLabel ? `${parentLabel} - ${env.label}` : env.label))
//     }
//     return list
//   }
// }

// =================
// Budget & Schedule
// =================

function getBidRangeLowerEnd (forecast, deals) {
  if (!forecast && !deals) return null
  if (deals && deals.length) {
    const floorCpms = deals.map(x => x.status === 'unknown' ? 0 : x.floorCPM)
    return Math.min(...floorCpms) / campsiteConfig.creatives.defaultDuration.image
  }
  return forecast.bidRanges.length ? forecast.bidRanges[0].cpmps : null
}

function getBidRangeUpperEnd (forecast, deals, usePublicExchange) {
  if (!forecast && !deals) return null
  if (deals && deals.length && !usePublicExchange) {
    const floorCpms = deals.map(x => x.status === 'unknown' ? 0 : x.floorCPM)
    const min = Math.min(...floorCpms)
    const max = Math.max(...floorCpms)
    return max === min ? null : max / campsiteConfig.creatives.defaultDuration.image
  }

  return forecast.bidRanges.length ? forecast.bidRanges[1].cpmps : null
}

function getEstimatedMaxBudget (forecast, hasMoment) {
  if (!forecast || (hasMoment)) return null

  const useBudget = !!forecast.budget
  const usedForecast = useBudget
    ? forecast.budget
    : forecast

  const p = useBudget ? 100 : 99
  const percentile = usedForecast.spending.find(x => x.percentile === p)
  return percentile?.amount || 0
}

function getMomentEventToEmit (info) {
  const { instance, line, momentObj, initialMomentObj, hasMoment, isMomentValid, isMomentUpdated } = info
  if (!hasMoment || !isMomentValid || isMomentUpdated) return null
  if (instance !== 'editLine' || !line.momentId) return 'createMoment'
  if (instance === 'editLine' && line.momentId && !_.isEqual(momentObj, initialMomentObj)) return 'updateMoment'
  else return null
}

function needToFixMoment (hasMoment, isMomentValid) {
  return !!hasMoment && !isMomentValid
}

function canByPassMomentSteps (hasMoment, isMomentValid, isMomentUpdated) {
  return !hasMoment || (isMomentValid && isMomentUpdated)
}

function GetMinMaxForecastBidRange (data, forecast) {
  var bidRanges = null
  if (data.deals?.length && forecast.dealBidRange && forecast.bidRanges.length) {
    if (data.usePublicExchange !== false) {
      var minCpmps = Math.min(forecast.dealBidRange.minCpmps, forecast.bidRanges[0].cpmps)
      var maxCpmps = Math.max(forecast.dealBidRange.maxCpmps, forecast.bidRanges[1].cpmps)
      bidRanges = [{ cpmps: minCpmps }, { cpmps: maxCpmps }]
    } else if (data.usePublicExchange === false) {
      bidRanges = [{ cpmps: forecast.dealBidRange.minCpmps }, { cpmps: forecast.dealBidRange.maxCpmps }]
    }
  }
  return bidRanges ?? forecast.bidRanges
}
