import campsiteConfig from '@/config/campsite.config'
import campaignsAPI from '@/services/campaigns.api'
import reportingAPI from '@/services/reporting.api'
import userAPI from '@/services/user.api'
import geoService from '@/services/geo.service'
import moment from 'moment'
import { DatetimeFormattingEnum } from '@/enums'
import flags from '@/plugins/rox/flags'

function audienceDefault () {
  return {
    lineName: '',
    lineExternalId: '',
    lineGoalType: '',
    saveAsAudience: false,
    startDate: moment().format(DatetimeFormattingEnum.DATE_ONLY),
    startTime: moment().add(1, 'hours').startOf('hours').format(DatetimeFormattingEnum.TIME_ONLY),
    endDate: moment().add(4, 'weeks').format(DatetimeFormattingEnum.DATE_ONLY),
    endTime: '11 PM',
    budget: null,
    maxCpm: null,
    targetNumberOfImpressions: '',
    priority: null
  }
}

function momentsDefault () {
  return {
    weather: null,
    finance: {
      stock: null,
      currency: null,
      crypto: null,
      commodity: null,
      index: null
    }
  }
}

function createCampaignStoreDefaults () {
  return {
    orderId: null,
    orderIdFromURL: null,

    campaign: {
      advertiser: '',
      advertiserExternalId: '',
      industry: '',
      campaignName: '',
      campaignExternalId: ''
    },
    defaultIndustry: {
      id: 3,
      name: 'Advertising Agencies & Related Services'
    },
    advertiserOrganization: {
      externalId: ''
    },
    exchange: {},
    audience: audienceDefault(),
    creatives: [],
    exchanges: null,
    deals: [],
    canOverwriteMaxBidWithDeal: true,
    canOverwriteDatesWithDeal: true,
    scheduleValidated: false,

    isMomentValid: null,
    momentConditions: momentsDefault(),
    momentId: null,
    momentCreated: false,
    mergeCreativeFormatRatio: null,
    mustUseReencodedDspCreative: null
  }
}

export default {
  namespaced: true,
  state: createCampaignStoreDefaults(),
  getters: {
    getIndustry: state => {
      return state.campaign.industry
    },
    getDefaultIndustry: state => {
      return state.defaultIndustry
    },
    getCampaignName: state => {
      return state.campaign.campaignName
    },
    getCampaignExternalId: state => {
      return state.campaign.campaignExternalId
    },
    getAdvertiser: state => {
      return state.campaign.advertiser
    },
    getAdvertiserOrganization: state => {
      return state.advertiserOrganization
    },
    getAdvertiserExternalId: state => {
      return state.campaign.advertiserExternalId
    },
    isNewAdvertiser: state => {
      return typeof state.campaign.advertiser === 'string'
    },
    getExchange: state => {
      return state.exchange
    },
    getLineName: state => {
      return state.audience.lineName
    },
    getLineExternalId: state => {
      return state.audience.lineExternalId
    },
    getLineGoalType: state => {
      return state.audience.lineGoalType || 'Impressions'
    },
    getLinePriority: state => {
      return state.audience.priority
    },
    getTargetNumberOfImpressions: state => {
      return state.audience.targetNumberOfImpressions
    },
    getSaveAsAudience: state => {
      return state.audience.saveAsAudience
    },
    getFullStartDate: state => {
      return moment(state.audience.startDate + ' ' + state.audience.startTime, DatetimeFormattingEnum.DATETIME).format()
    },
    getStartDate: state => {
      return state.audience.startDate
    },
    getStartTime: state => {
      return state.audience.startTime
    },
    getFullEndDate: state => {
      return moment(state.audience.endDate + ' ' + state.audience.endTime, DatetimeFormattingEnum.DATETIME_ROUNDED_HOUR).format()
    },
    getEndDate: state => {
      return state.audience.endDate
    },
    getEndTime: state => {
      return state.audience.endTime
    },
    getBudget: state => {
      return state.audience.budget
    },
    getMaxCpm: state => {
      return state.audience.maxCpm
    },
    getCreatives: state => {
      return state.creatives
    },
    getCreativesPerFormat: state => formatKey => {
      return state.creatives.filter(c => c.creativeFormat.key === formatKey)
    },
    getOrderId: state => {
      return state.orderId
    },
    getOrderIdFromURL: state => {
      return state.orderIdFromURL
    },
    getExchangesDetails: state => {
      return state.exchanges
    },
    deals: state => {
      return state.deals
    },
    validDealIds: state => {
      return state.deals.filter(x => x.status !== 'unknown').map(x => x.code)
    },
    dealIds: state => {
      return state.deals.map(x => x.code)
    },
    validDeals: state => {
      return state.deals.filter(x => x.status !== 'unknown')
    },
    canOverwriteWithDeal: state => {
      return {
        maxBid: state.canOverwriteMaxBidWithDeal,
        dates: state.canOverwriteDatesWithDeal
      }
    },
    isScheduleValidated: state => {
      return state.scheduleValidated
    },
    getCommonForecastOptions: (state, getters, rootState, rootGetters) => {
      const advertiser = getters.getAdvertiser
      const organizationId = rootGetters['user/getProfile'].organizationId

      const buyerId = advertiser && Object.keys(advertiser).length
        ? advertiser.buyerId
        : organizationId

      const options = {
        mapId: 'create-campaign',
        buyerId: buyerId,
        exchange: getters.getExchange.key,
        targeting: rootGetters['audience/selectedTargets'],
        startDate: getters.getFullStartDate,
        usePublicExchange: rootGetters['audience/getUsePublicExchange'],
        endDate: getters.getFullEndDate,
        deals: getters.dealIds,
        returnNativeResolution: flags.canSeeCreativeOptionsOnDspPartnerProposals.isEnabled() && getters.getMergeCreativeFormatRatio != null ? !getters.getMergeCreativeFormatRatio : null
      }

      const geography = rootGetters['audience/geography']
      if (geography && Object.keys(geography).length > 0) {
        // do NOT send geoJSON(s) to Forecast for performance
        // easier to go through "selectedGeoTargets" and re-format
        // than trying to go through already formated "geography"
        var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(rootGetters['audience/selectedGeoTargets'])
        options.geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
      }

      const environmentsApiFormat = rootGetters['audience/getEnvironmentsForecastApiFormat']
      if (environmentsApiFormat.groups && environmentsApiFormat.groups.length > 0) {
        options.segments = environmentsApiFormat
      }

      const creatives = getters.getCreatives
      if (creatives.length) {
        options.creatives = creatives.map(c => {
          return {
            creativeId: c.id,
            creativeFormatId: c.creativeFormat.id,
            creativeDuration: c.duration
          }
        })
      }

      // New! same call for Budgeted infos
      options.budget = {
        amount: getters.getBudget,
        maxcpmps: getters.getMaxCpm / campsiteConfig.creatives.defaultDuration.image
      }
      return options
    },
    isMomentValid: state => {
      return state.isMomentValid
    },
    momentConditions: state => {
      return state.momentConditions
    },
    hasMoment: state => {
      return !!state.momentConditions.weather || (!!state.momentConditions.finance && Object.keys(state.momentConditions.finance).some(k => !!state.momentConditions.finance[k]))
    },
    momentId: state => {
      return state.momentId
    },
    momentCreated: state => {
      return state.momentCreated
    },
    getMergeCreativeFormatRatio: state => {
      return state.mergeCreativeFormatRatio
    },
    getMustUseReencodedDspCreative: state => {
      return state.mustUseReencodedDspCreative
    }
  },
  mutations: {
    storeCampaignDetails (state, obj) {
      state.campaign = obj
    },
    storeExchangeDetails (state, obj) {
      state.exchange = obj.exchange
    },
    setAdvertiser (state, advertiser) {
      state.campaign.advertiser = advertiser
    },
    setAdvertiserOrganization (state, advertiserOrganization) {
      state.advertiserOrganization = advertiserOrganization
    },
    setAdvertiserExternalId (state, advertiserExternalId) {
      state.campaign.advertiserExternalId = advertiserExternalId
    },
    setLineName (state, name) {
      state.audience.lineName = name
    },
    setLineExternalId (state, externalId) {
      state.audience.lineExternalId = externalId
    },
    setSaveAsAudience (state, saveAsAudience) {
      state.audience.saveAsAudience = saveAsAudience
    },
    setStartDate (state, startDate) {
      state.audience.startDate = startDate
    },
    setStartTime (state, startTime) {
      state.audience.startTime = startTime
    },
    setEndDate (state, endDate) {
      state.audience.endDate = endDate
    },
    setEndTime (state, endTime) {
      state.audience.endTime = endTime
    },
    setBudget (state, budget) {
      state.audience.budget = budget
    },
    setMaxCpm (state, maxCpm) {
      state.audience.maxCpm = maxCpm
    },
    setLineGoalType (state, lineGoalType) {
      state.audience.lineGoalType = lineGoalType
    },
    setLinePriority (state, linePriority) {
      state.audience.priority = linePriority
    },
    setTargetNumberOfImpressions (state, targetNumberOfImpressions) {
      state.audience.targetNumberOfImpressions = targetNumberOfImpressions
    },
    addCreative (state, creative) {
      state.creatives.push(creative)
    },
    setCreatives (state, creatives) {
      state.creatives = creatives
    },
    setCreative (state, creative) {
      const creativeIndex = state.creatives.findIndex(c => c.id === creative.id)
      if (creativeIndex > -1) {
        Object.assign(state.creatives[creativeIndex], creative)
      }
    },
    removeCreative (state, creativeId) {
      const creativeIndex = state.creatives.findIndex(c => c.id === creativeId)
      if (creativeIndex > -1) {
        state.creatives.splice(creativeIndex, 1)
      }
    },
    resetCreatives (state) {
      state.creatives = []
    },
    resetAudience (state) {
      state.audience = Object.assign({}, audienceDefault())
    },
    resetCreateCampaign (state) {
      Object.assign(state, createCampaignStoreDefaults())
    },
    setOrderId (state, orderId) {
      state.orderId = orderId
    },
    setOrderIdFromURL (state, id) {
      state.orderIdFromURL = id
    },
    setExchangesDetails (state, exchanges) {
      state.exchanges = exchanges
    },
    setAdvertiserAndIndustry (state, requiredInfos) {
      // Needed for adding creatives page
      state.campaign.advertiser = requiredInfos.advertiser
      state.campaign.industry = requiredInfos.industry
    },
    clearAdvertiserAndIndustry (state) {
      state.campaign.advertiser = ''
      state.campaign.industry = ''
    },

    addDeal (state, deal) {
      const i = state.deals.findIndex(d => d.code === deal.code)
      if (i === -1) {
        state.deals.push(deal)
      }
    },
    removeDeal (state, deal) {
      const i = state.deals.findIndex(d => d.code === deal.code)
      if (i > -1) {
        state.deals.splice(i, 1)
      }
    },
    setDeal (state, deal) {
      const i = state.deals.findIndex(d => d.code === deal.code)
      if (i > -1) {
        state.deals.splice(i, 0, deal)
      }
    },
    setDeals (state, deals) {
      state.deals = deals
    },
    setCanOverwriteDealMaxBid (state, bool) {
      state.canOverwriteMaxBidWithDeal = bool
    },
    setCanOverwriteDealDates (state, bool) {
      state.canOverwriteDatesWithDeal = bool
    },
    setScheduleValidated (state, bool) {
      state.scheduleValidated = bool
    },
    setIsMomentValid (state, bool) {
      state.isMomentValid = bool
    },
    setMomentConditions (state, val) {
      state.momentConditions = Object.assign({}, state.momentConditions, val)
    },
    setMomentId (state, val) {
      state.momentId = val
    },
    setMomentCreated (state, val) {
      state.momentCreated = val
    },
    setMergeCreativeFormatRatio (state, bool) {
      state.mergeCreativeFormatRatio = bool
    },
    setMustUseReencodedDspCreative (state, bool) {
      state.mustUseReencodedDspCreative = bool
    }
  },
  actions: {
    createCampaign ({ dispatch, commit, getters, rootState, rootGetters }, type = null) {
      // Prefill empty fields (if necessary)
      if (!getters.getLineName) commit('setLineName', rootGetters['general/marketDefaultValues'].lineName)
      if (!getters.getBudget) commit('setBudget', 0)
      if (!getters.getMaxCpm) commit('setMaxCpm', 0)

      return dispatch('handleAdvertiser')
        .then(advertiser => {
          if (!advertiser.id) {
            throw new Error('Account not created.')
          }

          var newOrderPromise
          if (!getters.getOrderIdFromURL) {
            newOrderPromise = dispatch('createOrder', { advertiserId: advertiser.id, type })
          } else {
            newOrderPromise = reportingAPI.getOrder(getters.getOrderIdFromURL)
          }

          return newOrderPromise
            .then(order => {
              if (!order.id) {
              // TODO: some service that parses server response and builds error string if common cases ("unexpected", "unauthorized")
                throw new Error('Order not created.')
              }

              commit('setOrderId', order.id)

              return dispatch('createLine', { orderId: order.id, accountId: advertiser.id, orderStatus: order.status })
                .then(line => {
                  if (!line.id) {
                    throw new Error('Line not created.')
                  }

                  // save Line targeting as Audience
                  if (getters.getSaveAsAudience) {
                    var audience = {
                      name: getters.getLineName,
                      exchangeId: getters.getExchange.id,
                      organizationId: rootState.user.organization.id,
                      startDate: getters.getFullStartDate,
                      endDate: getters.getFullEndDate,
                      audienceSegments: rootState.audience.targeting,
                      numberOfFaces: rootState.audience.forecast.inventory.numberOfScreens,
                      numberOfVenues: rootState.audience.forecast.inventory.numberOfVenues,
                      reachAvailability: getters.getBudget > 0
                        ? rootState.audience.forecast.budget.impressions.value
                        : rootState.audience.forecast.impressions.value
                    }

                    const geography = rootState.audience.geography
                    if (geography && Object.keys(geography).length > 0) {
                      audience.geography = geography
                    }

                    const segments = rootState.audience.segments
                    if (segments.groups && segments.groups.length > 0) {
                      audience.segments = segments
                    }

                    dispatch('audience/addAudience', audience, { root: true })
                  }

                  // save Proposal's impressions
                  if (order.status === 'Proposal') {
                    if (rootState?.audience?.forecast?.budget?.impressions?.value) {
                      const impressions = rootState.audience.forecast.budget.impressions.value
                      line.budgeted = {
                        impressions: {
                          from: impressions,
                          to: impressions
                        }
                      }
                    }
                    commit('proposals/addLine', line, { root: true })
                  }

                  // "Save" creatives, because:
                  //   - if newly uploaded Creative, we need to tell API to "process" it
                  //   - they might have been edited
                  // var creatives = getters.getCreatives
                  // if (creatives.length) {
                  //   creatives.map(creative => {
                  //     creativesAPI.editCreative(creative)
                  //   })
                  // }

                  return dispatch('assignCreatives', { accountId: advertiser.id, lineId: line.id })
                    .then(assignments => {
                      assignments.map(a => {
                        if (!a.id) {
                          throw new Error('Creative not assigned.')
                        }
                      })

                      return order.id
                    })
                })
            })
        })
    },
    createOrder ({ getters }, obj) {
      const order = {
        accountId: obj.advertiserId,
        name: getters.getCampaignName ? getters.getCampaignName : 'New campaign',
        externalId: getters.getCampaignExternalId
      }

      if (getters.getIndustry && getters.getIndustry.id) {
        order.industryId = getters.getIndustry.id
      }

      if (obj.type && obj.type === 'proposal') {
        order.status = 'Proposal'
      }

      return campaignsAPI.createOrder(order)
    },
    createLine ({ getters, rootState, rootGetters }, data) {
      const line = {
        orderId: data.orderId,
        accountId: data.accountId,
        exchangeId: getters.getExchange.id,
        name: getters.getLineName,
        externalId: getters.getLineExternalId,
        startDate: getters.getFullStartDate,
        endDate: getters.getFullEndDate,
        budget: getters.getBudget,
        maxCpm: getters.getMaxCpm,
        targeting: rootState.audience.targeting,
        priority: getters.getLinePriority
      }

      const mergeCreativeFormatRatio = getters.getMergeCreativeFormatRatio
      const mustUseReencodedDspCreative = getters.getMustUseReencodedDspCreative
      if ((mergeCreativeFormatRatio === true || mergeCreativeFormatRatio === false) && (mustUseReencodedDspCreative === true || mustUseReencodedDspCreative === false)) {
        line.planLine = {
          mergeCreativeFormatRatio: mergeCreativeFormatRatio,
          mustUseReencodedDspCreative: mustUseReencodedDspCreative
        }
      }

      const isForAdServer = rootGetters['user/isForAdServer']

      if (getters.validDeals.length) {
        line.deals = getters.validDeals.map(deal => {
          return { code: deal.code, auctionType: deal.auctionType, deal: deal }
        })
      }

      line.usePublicExchange = rootGetters['audience/getUsePublicExchange']

      if (getters.hasMoment && getters.momentId) {
        line.momentId = getters.momentId
      }

      //  TODO : remove isForAdServer check when forecast implementation for polaris is added
      if (data.orderStatus === 'Proposal' && getters.getBudget && isForAdServer === false) {
        line.budgeted = {
          impressions: {
            from: rootState.audience.forecast.budget.impressions.value,
            to: rootState.audience.forecast.budget.impressions.value
          }
        }
      }

      const geography = rootState.audience.geography
      if (geography && Object.keys(geography).length > 0) {
        line.geography = geography
      }

      const segments = rootState.audience.segments
      if (segments.groups && segments.groups.length > 0) {
        line.segments = segments
      }

      return campaignsAPI.createLine(line)
        .then(createdLine => {
          if (!createdLine.deals) {
            createdLine.deals = line.deals || []
          }

          if (!createdLine.geography && line.geography) {
            createdLine.geography = line.geography
          }

          return createdLine
        })
    },
    assignCreatives ({ dispatch, getters }, data) {
      var assignmentPromises
      var creatives = getters.getCreatives
      if (creatives.length) {
        assignmentPromises = creatives.map(creative => {
          const assignment = {
            accountId: data.accountId,
            lineId: data.lineId,
            creativeId: creative.id,
            status: 'Active'
          }

          if (creative.processStatus === 'New') {
            // Advertiser might have been selected after the creative was uploaded,
            // so make sure it's attached to Creative
            if (!creative.accountId) { creative.accountId = data.accountId }

            // Industry is no longer required on Order
            // use Advertiser's if any, else use default Industry
            // because we MUST attach an(y) Industry
            if (!creative.industry) {
              creative.industry = getters.getIndustry
                ? getters.getIndustry.name
                : getters.getDefaultIndustry.name
            }

            return dispatch('creatives/editCreative', creative, { root: true })
              .then(creative => {
                return dispatch('assignCreative', assignment)
              })
          } else {
            return dispatch('assignCreative', assignment)
          }
        })

        return Promise.all(assignmentPromises)
      } else {
        return new Promise((resolve, reject) => {
          resolve([])
        })
      }
    },
    assignCreative ({ dispatch, getters }, assignment) {
      return campaignsAPI.createAssignment(assignment)
        .then(newAssignment => newAssignment)
    },
    handleAdvertiser ({ dispatch, getters }) {
      var advertiserPromise

      if (getters.isNewAdvertiser) {
        advertiserPromise = dispatch('createAdvertiser')
      } else {
        if (getters.getAdvertiserOrganization.externalId !== getters.getAdvertiserExternalId) {
          advertiserPromise = dispatch('updateAdvertiser')
        } else {
          advertiserPromise = new Promise((resolve, reject) => {
            resolve(getters.getAdvertiser)
          })
        }
      }

      return advertiserPromise
    },
    createAdvertiser ({ dispatch, commit, getters, rootState }) {
      const advString = getters.getAdvertiser
      const buyerId = rootState.user.organization.id

      const organization = {
        name: advString,
        externalId: getters.getAdvertiserExternalId,
        ownerOrganizationId: buyerId
      }

      return dispatch('createOrganization', organization)
        .then(organizationCreated => {
          const account = {
            advertiserId: organizationCreated.id,
            buyerId: buyerId,
            name: advString
          }
          return dispatch('createAccount', account)
            .then(accountCreated => {
              commit('setAdvertiser', accountCreated)
              commit('general/addAdvertiser', accountCreated, { root: true })
              return accountCreated
            })
        })
    },
    updateAdvertiser ({ getters }) {
      const data = {
        id: getters.getAdvertiserOrganization.id,
        externalId: getters.getAdvertiserExternalId
      }

      return userAPI.editOrganization(data)
        .then(() => getters.getAdvertiser)
    },
    createOrganization (context, data) {
      return campaignsAPI.createOrganization(data)
        .then(organization => organization)
    },
    createAccount (context, data) {
      return campaignsAPI.createAccount(data)
        .then(account => account)
    },
    loadOrganizationDeals ({ commit }, organizationId) {
      return userAPI.getOrganizationDeals(organizationId)
        .then(deals => deals)
    }
  }
}
