<template lang="pug">
  div(style='color: rgba(0, 0, 0, 0.87)')
    v-layout.py-0(row wrap)
      v-flex.pb-0(xs12 lg3 text-xl-left)
        .text-body-1.font-weight-bold.mr-3 Specifications
      v-flex(xs12 lg9 xl8 text-md-left)
        v-radio-group.mt-0.merge-creative-format-option-group(v-model='mergeCreativeFormatRatio' hide-details)
          v-radio.schedule-option(ref="mergeCreativeFormatOption" label='Use merged format ratio' value='true' color='primary')
          v-radio.schedule-option(ref="screenNativeResolutionOption" label='Use screen native resolution' value='false' color='primary')
          .text-caption.text--disabled.merge-creative-format-label {{ mergeCreativeFormatTooltip }}
          a#creative-specs-link.text-caption(v-if='componentConfig.links.creativeSpecs && mergeCreativeFormatRatio === "true"' :href='componentConfig.links.creativeSpecs' target='_blank' style='text-decoration:none;') See the complete creative specs
      v-flex.pb-0(xs12 lg3 text-xl-left)
        .text-body-1.font-weight-bold.mr-3 Assets
      v-flex(xs12 lg9 xl8 text-md-left)
        v-radio-group.mt-0.use-reencoded-creative-option-group(v-model='mustUseReencodedDspCreative' hide-details :disabled='mergeCreativeFormatRatio === "false"')
          v-radio.schedule-option(ref="reencodeDspCreativeOption" label='Use re-encoded assets' value='true' color='primary')
          v-radio.schedule-option(ref="useOriginalAssetOption" label='Use original assets' value='false' color='primary')
          .text-caption.text--disabled.use-reencoded-creative-label {{ useReencodedCreativeTooltip }}
</template>
<script>
import componentConfigService from '@/services/componentConfig'

export default {
  props: {
    initMergeCreativeFormatRatio: {
      type: Boolean,
      default: () => true
    },
    initMustUseReencodedDspCreative: {
      type: Boolean,
      default: () => true
    },
    isOnAuctionPackagePage: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      mergeCreativeFormatRatio: this.initMergeCreativeFormatRatio.toString(),
      mustUseReencodedDspCreative: this.initMustUseReencodedDspCreative.toString()
    }
  },
  mounted () {
    this.$store.commit(this.storeName + '/setMergeCreativeFormatRatio', this.initMergeCreativeFormatRatio)
    this.$store.commit(this.storeName + '/setMustUseReencodedDspCreative', this.initMustUseReencodedDspCreative)
  },
  computed: {
    componentConfig () {
      return componentConfigService()
    },
    mergeCreativeFormatTooltip () {
      if (this.mergeCreativeFormatRatio === 'true') {
        return 'Creative formats sent in bid requests will use formats that combine many different, but similar aspect ratios. Creatives can be slightly cropped to accommodate suppliers’ screen specifications. We suggest avoiding positioning logos and text close to the edges. For best results, leave a 5% margin on each side.'
      } else {
        return 'Creative formats sent in bid requests will use the native screen resolution set by the media owner.'
      }
    },
    useReencodedCreativeTooltip () {
      if (this.mustUseReencodedDspCreative === 'true') {
        return 'Submitted assets are dynamically re-encoded using media owners recommendations to ensure proper playback.'
      } else {
        return ''
      }
    },
    storeName () {
      return this.isOnAuctionPackagePage ? 'auctionPackage' : 'createCampaign'
    }
  },
  watch: {
    mergeCreativeFormatRatio (newV) {
      var boolValue = Boolean(newV === 'true')
      this.mustUseReencodedDspCreative = boolValue ? 'true' : 'false'
      this.$store.commit(this.storeName + '/setMergeCreativeFormatRatio', boolValue)
      if (this.isOnAuctionPackagePage || this.$flags.canSeeCreativeOptionsOnDspPartnerProposals.isEnabled()) {
        this.$emit('updateForecast', true)
      }
    },
    mustUseReencodedDspCreative (newV) {
      this.$store.commit(this.storeName + '/setMustUseReencodedDspCreative', Boolean(newV === 'true'))
    }
  }
}
</script>
