import { render, staticRenderFns } from "./proposal.vue?vue&type=template&id=14866a5b&scoped=true&lang=pug"
import script from "./proposal.vue?vue&type=script&lang=js"
export * from "./proposal.vue?vue&type=script&lang=js"
import style0 from "./proposal.vue?vue&type=style&index=0&id=14866a5b&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14866a5b",
  null
  
)

export default component.exports