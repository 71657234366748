<template lang="pug">
  div
    vue-headful(:title="componentConfig.branding.title(headTitle)")

    //- LOADING STATE 1
    v-container(fluid style='height: calc(100vh - 48px); position: fixed;' v-if='!getIsThemeLoaded && token')
      loadingBroadsignLogo

    div(v-else)
      //- LOADING STATE 2
      div.bgcolor(v-if='isLoading' :style="`min-height: ${$vuetify.breakpoint.mdOnly? '416px' : '332px'};`")
        v-container
          v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
            v-col(cols=12)
              v-skeleton-loader.mb-1(type='image' height=42 width='70%')
              v-skeleton-loader.mt-2(type='text' width='30%')
            v-col.mb-2.mt-0.pt-0
              div(v-if="!isPublic || can('proposal').create.shareLink")
                v-skeleton-loader.d-inline-block.mr-2(type='image' height=36 width=100)
                v-skeleton-loader.d-inline-block(type='image' height=36 width=115)

          v-row.my-0.mt-n1(:dense='$vuetify.breakpoint.xsOnly')
            v-col(cols='12' sm='6' lg='3')
              v-skeleton-loader(type='image' height=80)
            v-col(cols='12' sm='6' lg='4')
              v-skeleton-loader(type='image' height=80)
            v-col(cols='6'  sm='6' lg='2')
              v-skeleton-loader(type='image' height=80)
            v-col(cols='6' sm='6'  lg='3')
              v-skeleton-loader(type='image' height=80)

      v-container.pt-0(v-if='isLoading' text-left class='transparent')
        v-row.my-0(row wrap fill-height)
          v-col.pa-0.px-sm-3(cols='12')
            v-expansion-panels.mt-md-n12(multiple)
              v-expansion-panel
                v-expansion-panel-header
                  v-skeleton-loader(type='heading')
              v-expansion-panel
                v-expansion-panel-header
                  v-skeleton-loader(type='heading')

      div.bgcolor(v-if='!isLoading' :style="`padding-bottom: ${$vuetify.breakpoint.mdAndUp? '60px' : '0px'};`")
        v-container
          v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
            v-col.pb-0(cols=12)
              .text-h4.text-truncate#proposal-name(class='white--text') {{ proposal.name }}
              .text-body-2.white--text.mb-1#proposed-by-sentence {{ proposedBySentence }}
            v-col.mb-2.pt-1
              .d-flex(:class="{'my-1': !isPublic}")
                template(v-if="!isPublic || can('proposal').create.shareLink")
                  v-btn.primary--text.mr-2.px-3#share-btn(color='white' @click="generateShareToken")
                    |  Share
                confirmationDialog.convert-plan-confirmation(
                  v-if="canConvertProposalToCampaign"
                  @confirmed="convertProposal"
                  :details="{title: 'Convert to Campaign', msg: `Are you sure you want to convert this ${proposalLabel.toLowerCase()} to a campaign?`, btnLabel: 'Convert to Campaign'}"
                )
                  template(v-slot:customActivator='')
                    v-btn.primary--text.mr-2.px-3#convert-to-campaign(color='white' v-if="!isPublic" @click="$root.$emit('openConfirmDialog', 'Convert to Campaign')") Convert to Campaign

                v-tooltip(v-if="!isForAdServer" top, dark, color='secondary')
                  template(v-slot:activator="{ on }")
                    v-btn#export-csv-btn(icon dark v-on="on" @click="exportCsv" :loading="isExportingCsv")
                      v-icon mdi-download
                  span.text-caption Export proposal in CSV

                .action-menu-wrapper(v-if="!isPublic")
                  v-menu(offset-y :close-on-content-click="false" v-model="actionsMenuOpen" :attach='true')
                    template(v-slot:activator='{ on }')
                      v-btn#more-menu(icon dark v-on='on' @click.stop='')
                        v-icon mdi-dots-vertical
                    v-list(dense)
                      editCampaignDialog(
                        @edited="updateProposal"
                        :campaign='proposal'
                      )
                        template(v-slot:customActivator='')
                          v-list-item#edit-btn(@click="$root.$emit('openEditCampaignDialog', 'editProposalName')")
                            v-list-item-icon.mr-4
                              v-icon mdi-pencil
                            v-list-item-title Edit
                      generateAuctionPackagesDialog(v-if='canGenerateAuctionPackagesFromProposal' :lines='lines' :authToken="$store.getters['user/getToken']" :proposal='proposal')
                        template(v-slot:customActivator='')
                          v-list-item#generate-auction-packages-btn(@click="$root.$emit('openGenerateAuctionPackagesDialog', `Generate Auction Packages`)")
                            v-list-item-icon.mr-4
                              v-icon mdi-tag
                            v-list-item-title Generate Auction Packages
                      transferProposalDialog(v-if='can("proposal").update && componentConfig.campaigns.isTransferProposalShown' @transferProposal='transferProposal')
                      confirmationDialog.duplicate-plan-confirmation(
                        @confirmed="duplicateProposal"
                        ref="confirmationDialogDuplicateProposal"
                        :details="{title: `Duplicate ${proposalLabel}`, msg: `Are you sure you want to duplicate this ${proposalLabel.toLowerCase()}?`, btnLabel: `Duplicate ${proposalLabel}`}"
                      )
                        template(v-slot:customActivator='')
                          v-list-item#duplicate-btn(@click="$root.$emit('openConfirmDialog', `Duplicate ${proposalLabel}`)")
                            v-list-item-icon.mr-4
                              v-icon mdi-content-copy
                            v-list-item-title Duplicate
                      v-divider
                      confirmationDialog.archive-plan-confirmation(
                        @confirmed="archiveProposal"
                        ref="confirmationDialogArchiveProposal"
                        :details="{title: `Archive ${proposalLabel}`, msg: `Are you sure you want to archive this ${proposalLabel.toLowerCase()}?`, btnLabel: `Archive ${proposalLabel}`}"
                      )
                        template(v-slot:customActivator='')
                          v-list-item#archive-btn(@click="$root.$emit('openConfirmDialog', `Archive ${proposalLabel}`)")
                            v-list-item-icon.mr-4
                              v-icon mdi-delete
                            v-list-item-title Archive

          v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
            v-col(cols='12' sm='6' lg='3')
              v-card(dark flat color='rgba(0, 0, 0, .2)' height='100%')
                v-card-text.white--text
                  .text-overline Advertiser
                  .text-h6.font-weight-regular.text-truncate#advertiser-name {{ proposal.account }}
            v-col(cols='12' sm='6' lg='4')
              v-card(dark flat color='rgba(0, 0, 0, .2)' height='100%')
                v-card-text.white--text
                  .text-overline Schedule
                  .text-h6.font-weight-regular#schedule {{ duration }}
            v-col(cols='6'  lg='2')
              v-card(dark flat color='rgba(0, 0, 0, .2)' height='100%')
                v-card-text.white--text
                  .text-overline Total Budget
                  .text-h6.font-weight-regular#total-budget {{ totalBudget }}
            v-col(cols='6' lg='3')
              v-card(dark flat color='rgba(0, 0, 0, .2)' height='100%')
                v-card-text.white--text(v-if='componentConfig.campaigns.impressions.useEstimated')
                  .text-overline Est. Impressions
                  .text-h6.font-weight-regular#estimated-impressions
                    v-icon.mb-1.mr-1(color='white') mdi-account-multiple
                    | {{ totalImpressions }}
                v-card-text.white--text(v-else)
                  .text-overline Target Impressions
                  .text-h6.font-weight-regular#targeted-impressions {{ totalTargetImpressions }}

      v-container.pt-0(v-if='!isLoading' text-left class=''  :fluid="$vuetify.breakpoint.mdOnly")
        v-row.my-0()
          v-col.pa-0.px-sm-3(cols='12')
            v-expansion-panels.mt-md-n12(multiple v-model='panelpropo')
              proposal-line(
                v-for='(line, indx) in lines'
                :id="'line' + indx"
                :key='line.id'
                :line='line'
                :isPublic='isPublic'
                :isDspPartnerProposal='canGenerateAuctionPackagesFromProposal'
                :canUpdate='can("proposal").update'
                :panelIndex='indx'
                @openEditLineDialog='openEditLineDialog'
                @openTroubleshootingTool='openTroubleshootingTool'
                @closedDialog='closePanel'
              )

        v-btn.my-2(
          v-if='!isPublic || can("proposal").create.newLine'
          text x-large block
          color='primary'
          @click="openEditLineDialog"
        ) Add Line Order

      editLineDialog.d-inline-block(
        :src="editLineSource"
        :data='lineToBeEdited'
        :dialogOpen="editLineDialogOpen"
        :campaign='proposal'
        @close="closeEditLineDialog"
      )

      shareDialog(
        parentComponent= 'proposals'
        :label= 'proposalLabel'
        :shareDialogOpen = 'shareDialogOpen'
        :isForAdServer = 'isForAdServer'
        @dialogOpenChanged = 'dialogOpenChanged'
      )

      v-container.pt-0()
        v-row.my-0()
          v-col.pa-0.px-sm-3.plans-info(cols='12')
            p.caption.grey--text Please note that numbers presented in that {{ proposalLabel.toLowerCase() }} are estimations based on historical data intended to help with campaign planning and are directional only. Pricing, impressions, demographic concentration and inventory availability can change at any moment without notice.
            p.caption.grey--text Due to its non-guaranteed and changing nature, the estimates generated by the {{ proposalLabel.toLowerCase() }} tool should not be used as the basis for any campaign guarantees or similar contractual purposes.

      v-navigation-drawer.proposal-drawer(v-model='drawerOpen' fixed right temporary width='60%')
        troubleshooting-tool(v-if='troubleshootingToolOpen' :line='troubleshootLine' :campaign-status='proposal.status')
</template>

<script>
import proposalLine from '@/components/proposalLine.vue'
import transferProposalDialog from '@/components/transferProposalDialog.vue'
import confirmationDialog from '@/components/confirmationDialog.vue'
import editCampaignDialog from '@/components/editCampaignDialog.vue'
import generateAuctionPackagesDialog from '@/components/generateAuctionPackagesDialog.vue'
import editLineDialog from '@/components/editLineDialog.vue'
import loadingBroadsignLogo from '@/components/loadingBroadsignLogo.vue'
import troubleshootingTool from '@/components/troubleshootingTool.vue'
import shareDialog from '@/components/shareDialog.vue'
import { OrganizationTypeEnum } from '@/enums'

import componentConfigService from '@/services/componentConfig'
import campaignsApi from '@/services/campaigns.api'
import csvService from '@/services/csv.service'

import { mapState, mapGetters } from 'vuex'
import tracking from '@/services/tracking'

export default {
  components: {
    proposalLine,
    transferProposalDialog,
    confirmationDialog,
    editCampaignDialog,
    generateAuctionPackagesDialog,
    editLineDialog,
    loadingBroadsignLogo,
    troubleshootingTool,
    shareDialog
  },
  data () {
    return {
      panelpropo: [0],
      shareDialogOpen: false,
      scrollOptions: {
        duration: 0,
        easing: 'easeInOutCubic',
        offset: 30
      },
      actionsMenuOpen: false,
      editLineDialogOpen: false,
      editLineSource: '',
      lineToBeEdited: {},
      drawerOpen: false,
      troubleshootingToolOpen: false,
      troubleshootLine: null,
      isLoadingExchangeTargeting: true,
      isExportingCsv: false
    }
  },
  computed: {
    ...mapState('proposals', {
      isLoadingProposal: state => state.isLoadingProposal,
      proposal: state => state.proposal,
      lines: state => state.lines,
      lineDetails: state => state.details
    }),
    ...mapGetters('proposals', [
      'proposedBySentence',
      'duration',
      'totalBudget',
      'totalImpressions',
      'totalTargetImpressions',
      'lineToBeOpenedNext'
    ]),
    ...mapGetters('user', [
      'getProfile'
    ]),
    ...mapGetters('general', [
      'getIsThemeLoaded'
    ]),
    isLoading () {
      return Boolean(this.isLoadingProposal || this.isLoadingExchangeTargeting)
    },
    token () {
      return this.$route.query ? this.$route.query.token : null
    },
    proposalId () {
      return this.$route.params.id
    },
    isPublic () {
      return !!this.token
    },
    headTitle () {
      return this.proposal ? this.proposal.name : this.proposalLabel
    },
    proposalLabel () {
      return this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
    },
    componentConfig () {
      return componentConfigService(this.isForAdServer)
    },
    isForAdServer () {
      const isForAdServer = this.proposal?.isForAdServer || false
      return isForAdServer
    },
    canGenerateAuctionPackagesFromProposal () {
      return this.proposal.organizationType === OrganizationTypeEnum.PARTNER_DSP && this.$store.getters['general/isAuctionPackageVisible']
    },
    canConvertProposalToCampaign () {
      return this.can('campaign').create.default && this.proposal.organizationType !== OrganizationTypeEnum.PARTNER_DSP
    }
  },

  created () {
    if (this.$vuetify.breakpoint.xsOnly) this.panelpropo = []

    if (!this.proposal || this.proposal.id !== parseInt(this.proposalId)) {
      this.$store.commit('proposals/resetStore')
      this.$store.dispatch('proposals/fetch', this.proposalId)
        .then(notProposalError => {
          if (notProposalError) {
            this.$router.push({ name: '404' })
          }
        })
        .catch(err => {
          var name = '404'

          if (err) {
            if ((err.response && err.response.status === 401) || err === 'No current user') {
              name = 'Access Denied'
            }
          }

          this.$router.push({ name })
        })
    } else {
      const n = this.lineToBeOpenedNext
      if (this.lineDetails[this.lines[n].id].isLoadingForecast) this.$store.dispatch('proposals/getLineForecast', this.lines[n].id)
    }

    this.$store.dispatch('general/getExchangesTargeting').then(() => {
      this.isLoadingExchangeTargeting = false
    })
  },

  mounted () {
    if (this.lineToBeOpenedNext > 0 && this.lineToBeOpenedNext !== this.panelpropo[0]) {
      this.panelpropo[0] = this.lineToBeOpenedNext
    }
    setTimeout(() => this.scrollToLine(), 500)
  },

  methods: {
    can (resource) {
      return this.$store.getters['user/permissions'](resource)
    },
    closePanel (index) {
      this.panelpropo = this.panelpropo.filter(x => x !== index)
    },
    dialogOpenChanged (dialogState) {
      this.shareDialogOpen = dialogState
    },
    transferProposal (organizationIdToTransferTo) {
      this.actionsMenuOpen = false
      var { organizationId } = this.getProfile

      // required
      var data = {
        proposalId: this.proposalId,
        accountId: this.proposal.accountId
      }

      // optional - only if converting to another Organization
      if (organizationIdToTransferTo !== organizationId) {
        data.accountName = this.proposal.account
        data.organizationId = organizationIdToTransferTo
      }

      this.$store.dispatch('proposals/transfer', data)
        .then(proposal => {
          if (proposal && proposal.id) {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: proposal.name + ' successfully transferred'
            })
          }
        })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Was not able to transfer this proposal. Please try again.'
          })
        })
    },
    convertProposal () {
      this.actionsMenuOpen = false
      this.$store.dispatch('proposals/convertToCampaign')
        .then(proposal => {
          if (proposal && proposal.id) {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: proposal.name + ' successfully converted to campaign'
            })
            this.$store.commit('proposals/resetStore')
            this.$router.push({ name: 'report-campaign', params: { campaignId: proposal.id } })
            tracking.sendEvent(['ga'], 'convertedProposalToCampaign')
          }
        })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Could not convert this proposal. Please try again.'
          })
        })
    },
    generateShareToken () {
      this.shareDialogOpen = true
      this.$store.dispatch('proposals/generateToken')
      tracking.sendEvent(['ga'], 'openedShareProposalDialog')
    },
    scrollToLine () {
      if (this.panelpropo.length === 1 && this.panelpropo[0] > 0) {
        this.$vuetify.goTo('#line' + this.panelpropo[0], this.scrollOptions)
      }
    },
    archiveProposal () {
      this.actionsMenuOpen = false
      this.$store.dispatch('proposals/archive')
        .then(removed => {
          if (removed) {
            const msg = this.proposal.name + ' successfully archived'
            this.$store.commit('proposals/resetStore')
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            this.$router.push({ name: 'Campaigns Overview' })
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Something went wrong while archiving proposal.'
            })
          }
        })
        .catch(error => {
          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },
    duplicateProposal () {
      this.actionsMenuOpen = false
      this.$store.dispatch('proposals/duplicate')
        .then(copy => {
          if (copy) {
            const msg = this.proposal.name + ' successfully duplicated'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            window.open(`${window.location.origin}/proposals/${copy.id}`, '_blank')
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Something went wrong while duplicating proposal.'
            })
          }
        })
        .catch(error => {
          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },
    updateProposal (proposal) {
      this.actionsMenuOpen = false

      const actionsToTrackOnSuccess = []
      if (this.proposal.name !== proposal.name) {
        actionsToTrackOnSuccess.push('changedProposalName')
      }

      if (this.proposal.accountId !== proposal.accountId) {
        actionsToTrackOnSuccess.push('changedAdvertiser')
      }

      this.$store.dispatch('proposals/updateProposal', proposal)
        .then(updatedProposal => {
          if (updatedProposal) {
            const msg = updatedProposal.name + ' successfully updated'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })

            actionsToTrackOnSuccess.map(action => {
              const label = action === 'changedProposalName'
                ? 'type name: ' + updatedProposal.name
                : 'select advertiser: ' + updatedProposal.account

              tracking.sendEvent(['ga'], action, { label })
            })

            tracking.sendEvent(['ga'], 'savedProposal')
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Could not update proposal. Please try again.'
            })
          }
        })
        .catch(error => {
          console.log(error)

          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },
    openEditLineDialog (obj) {
      this.lineToBeEdited = obj ? obj.line : null
      this.editLineSource = obj ? obj.src : null
      this.editLineDialogOpen = true
    },
    closeEditLineDialog (callback = null) {
      if (callback && callback.name) {
        if (callback.name === 'openNewLine') {
          const n = this.lines.length - 1
          this.panelpropo = [n]
          if (this.lineDetails[this.lines[n].id].isLoadingForecast) this.$store.dispatch('proposals/getLineForecast', this.lines[n].id)
          this.scrollToLine()
        }

        if (callback.name === 'refreshWeatherMoment') {
          this.$root.$emit('refreshWeatherMoment', callback.args)
        }
      }

      this.editLineDialogOpen = false
    },
    openTroubleshootingTool (line) {
      this.troubleshootLine = line
      this.troubleshootingToolOpen = true
      this.drawerOpen = true
    },
    exportCsv () {
      this.isExportingCsv = true
      campaignsApi.getCampaignForCsv(this.proposal.id).then((data) => {
        csvService.csvExport(data, 'Lines for ' + this.proposal.name)
        this.isExportingCsv = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-expansion-panel-content__wrap {
  padding: 0px;
}

.bgcolor {
  background: linear-gradient(45deg, var(--v-primary-darken1) 10%, var(--v-primary-lighten1) 96%);
  margin-top: -1px;
}
</style>
