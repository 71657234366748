import moment from 'moment'

import defaultExchangeValues from '@/services/defaultExchangeValues'
import helpers from '@/services/helpers.service'
import auctionPackageApi from './auctionPackage.api'
import momentService from '@/web-components/moments/services'

export default {
  csvFormatter,
  searchUniqueValuesForColumn,
  buildApiFiltersString,
  createAuctionPackageFromLine
}

function csvFormatter (ap) {
  return {
    'Auction Package Display Name': ap.name,
    'External Id': ap.externalId,
    'Organization Name': ap.organizationName,
    'Created By': ap.owner,
    'Deal Id': ap.code,
    'Weekly Impressions': ap.estimatedImpressions || 'N/A',
    'Number of Screens': ap.numberOfFaces,
    'Number of Venues': ap.numberOfVenues,
    'Last Updated On': ap.lastUpdatedOn,
    'Floor Price': ap.floorPrice,
    'Start Date': ap.startDate ? moment(ap.startDate).format('MM/DD/YYYY') : null,
    'End Date': ap.endDate ? moment(ap.endDate).format('MM/DD/YYYY') : null,
    'Creative Type': ap.targetedCreativeType,
    'Creative Format': ap.targetedCreativeFormat,
    'Min Bid Range': ap.minBidRange,
    'Max Bid Range': ap.maxBidRange,
    Market: getExchangeLabelFromExchange(ap.exchange),
    Publishers: ap.targetedSuppliers,
    Details: ap.shareLink
  }

  function getExchangeLabelFromExchange (exchange) {
    const exchangeValues = defaultExchangeValues.getAllDefaultValues().find(x => x.market === exchange)
    return exchangeValues.label
  }
}

function buildApiFiltersString (filtersIn = [], csvExport = false) {
  var filtersOut = []
  var filtersString = ''

  filtersIn.map(filter => {
    const operator = filter.operator || 'eq'
    let filterString = ''

    let escapedFilter, quotedValue
    if (filter.name !== 'multiValuesSearch') {
      escapedFilter = helpers.replaceSpecialCharacters(filter.value).toLowerCase()
      quotedValue = typeof filter.value === 'number' ? filter.value : "'" + filter.value + "'"
    }

    let emailField = 'ownerEmail'
    let orgNameField = 'organization/name'

    if (csvExport) {
      emailField = getCsvField(emailField)
      orgNameField = getCsvField(orgNameField)

      if (filter.field) {
        filter.field = getCsvField(filter.field)
      } else if (filter.fields) {
        filter.fields = filter.fields.map(f => getCsvField(f))
      }
    }

    switch (filter.name) {
      // SEARCH
      case 'search':
        filtersOut.push("(contains(tolower(code), '" + escapedFilter + "') or contains(tolower(" + emailField + "), '" + escapedFilter + "') or contains(tolower(name), '" + escapedFilter + "') or contains(tolower(" + orgNameField + "), '" + escapedFilter + "') or contains(tolower(externalId), '" + escapedFilter + "') )")
        break
      case 'multiFieldsSearch':
        filtersOut.push(multiFieldSearchFilterToApiString(filter, escapedFilter))
        break
      case 'multiValuesSearch':
        filtersOut.push(multiValuesSearchFilterToApiString(filter))
        break
      case 'mainStatus':
        if (filter.value === 'All') { filtersOut.push("(status eq 'Active' or status eq 'Inactive')") }
        if (filter.value === 'Archived') { filtersOut.push(filter.keyName + ' ' + operator + ' ' + "'" + filter.value + "'") }
        break
      default:
        filterString = filter.name + ' ' + operator + ' ' + quotedValue
        filtersOut.push(filterString)
    }
  })

  if (filtersOut.length) {
    filtersString = '$filter=' + filtersOut.join(' and ')
  }
  return filtersString
}

function multiValuesSearchFilterToApiString (filter) {
  // let escapedFilter
  switch (filter.operator) {
    case 'has any value':
      return filter.field + ' ne null'
    case 'is none of':
      return 'not ' + addValuesFilters(filter)
    case 'is any of':
      return addValuesFilters(filter)
    default:
      return ''
  }

  function getExchangeMarketFromCountryCode (exchangeLabel) {
    return defaultExchangeValues.getAllDefaultValues().find(x => x.countryCode === exchangeLabel).market
  }

  function addValuesFilters (filter) {
    let filterString = '('
    let filterTemplateAction
    switch (filter.field) {
      case 'code':
        filterTemplateAction = (value) => {
          const lastIndexOfParanthese = value.lastIndexOf('(')
          if (lastIndexOfParanthese > 0) {
            const escapedCode = helpers.replaceSpecialCharacters(value.slice(lastIndexOfParanthese + 1, value.length - 1)).toLowerCase()
            filterString += `tolower(code) eq '${escapedCode}'`
          }
        }
        break
      case 'exchange/key':
      case getCsvField('exchange/key'):
        filterTemplateAction = (value) => {
          const escapedFilter = helpers.replaceSpecialCharacters(getExchangeMarketFromCountryCode(value)).toLowerCase()
          filterString += `tolower(${filter.field}) eq '` + escapedFilter + "'"
        }
        break
      case 'targetedSuppliers':
      case 'targetedCreativeFormat':
      case 'targetedCreativeType':
        filterTemplateAction = (value) => {
          const escapedFilter = helpers.replaceSpecialCharacters(value).toLowerCase()
          filterString += `contains(concat(concat(',',tolower(${filter.field})),','), ',${escapedFilter},')`
        }
        break
      case 'status':
        filterTemplateAction = (value) => {
          const escapedFilter = helpers.replaceSpecialCharacters(value)
          filterString += `${filter.field} eq '${escapedFilter}'`
        }
        break
      default:
        filterTemplateAction = (value) => {
          const escapedFilter = helpers.replaceSpecialCharacters(value).toLowerCase()
          filterString += `tolower(${filter.field}) eq '${escapedFilter}'`
        }
    }
    filter.values.forEach((value, i) => {
      if (i > 0) { filterString += ' or ' }
      filterTemplateAction(value)
    })
    filterString += ')'
    return filterString
  }
}

function multiFieldSearchFilterToApiString (filter, escapedFilter) {
  let filterString = '('
  filter.fields.forEach((field, i) => {
    if (i > 0) { filterString += ' or ' }
    switch (field) {
      case 'feesLabel':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "'))"
        break
      case 'fee1Label':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "') and f/layoutOrder eq 1)"
        break
      case 'fee2Label':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "') and f/layoutOrder eq 2)"
        break
      case 'fee3Label':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "') and f/layoutOrder eq 3)"
        break
      case 'fee4Label':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "') and f/layoutOrder eq 4)"
        break
      case 'fee5Label':
        filterString += "fees/any(f:contains(f/label,'" + escapedFilter + "') and f/layoutOrder eq 5)"
        break
      default: {
        filterString += 'contains(tolower(' + field + "), '" + escapedFilter + "')"
      }
    }
  })
  filterString += ')'
  return filterString
}

function searchUniqueValuesForColumn (column, searchValue) {
  const fieldsToSearch = [column]
  if (column === 'code') { fieldsToSearch.push('name') }

  const filters = [{ name: 'multiFieldsSearch', value: searchValue, fields: fieldsToSearch }]

  return auctionPackageApi.getAuctionPackages(0, 0, column, 'asc', filters)
    .then(data => {
      let uniqueValues = []
      switch (column) {
        case 'organization/name':
          uniqueValues = [...new Set(data.map(ap => ap.organization.name))]
          break
        case 'code':
          uniqueValues = [...new Set(data.map(ap => ap.name + ' (' + ap.code + ')'))]
          break
        case 'targetedSuppliers':
        case 'targetedCreativeFormat':
        case 'targetedCreativeType':
          uniqueValues = new Set()
          data.forEach(ap => ap[column].split(',').forEach(x => uniqueValues.add(x)))
          uniqueValues = [...uniqueValues].filter(x => x.toLowerCase().includes(searchValue.toLowerCase())).sort()
          break
        default:
          uniqueValues = [...new Set(data.map(ap => ap[column]))]
      }

      return uniqueValues.filter(x => x).slice(0, 10)
    })
}

async function createAuctionPackageFromLine (line, authToken, defaultStatus = 'Inactive', linesOrganizationId) {
  const momentId = line.momentId ? (await momentService.cloneMoment(line.momentId, authToken)).id : null
  const exchange = defaultExchangeValues.getAllDefaultValues().find(x => x.id === line.exchangeId) || {}
  let hasGeography = line.geography && Object.keys(line.geography).length > 0
  if (hasGeography && line.geography.filters) {
    hasGeography = line.geography.filters.length > 0
  }
  return {
    name: line.name,
    externalId: line.externalId,
    startDate: line.startDate,
    endDate: line.endDate,
    exchange: { id: line.exchangeId, key: exchange.market },
    currency: line.currency,
    targeting: line.targeting,
    geography: hasGeography ? line.geography : null,
    segments: line.segments?.groups && Object.keys(line.segments.groups).length > 0 ? line.segments : null,
    floorPrice: line.floorPrice,
    ceilingPrice: line.ceilingPrice,
    mergeCreativeFormatRatio: line.planLine ? line.planLine.mergeCreativeFormatRatio : true,
    mustUseReencodedDspCreative: line.planLine ? line.planLine.mustUseReencodedDspCreative : true,
    momentId: momentId,
    deals: line.deals,
    usePublicExchange: line.usePublicExchange,
    fees: line.fees || [{
      type: 'Percentage',
      label: 'Broadsign Ads Fee',
      layoutOrder: 1,
      value: 0.05
    }],
    status: defaultStatus,
    organization: {
      id: linesOrganizationId
    }
  }
}

function getCsvField (field) {
  switch (field) {
    case 'exchange/key':
      return 'exchange'
    case 'organization/name':
      return 'organizationName'
    case 'ownerEmail':
      return 'owner'
    default:
      return field
  }
}
